import Alpine from "alpinejs";

Alpine.data("deviceRenewals", () => ({
  search: "",
  selectedDeviceSlugs: [],
  selectAll: false,

  init() {
    this.$watch("search", () => {
      // Use nextTick to wait for the DOM to update fully
      this.$nextTick(() => {
        // Update "select all" based on whether all visible devices are selected
        const visibleDeviceSlugs = this.getVisibleDeviceSlugs();

        this.selectAll =
          visibleDeviceSlugs.length <= this.selectedDeviceSlugs.length &&
          visibleDeviceSlugs.length > 0;
      });
    });

    this.$watch("selectedDeviceSlugs", () => {
      const visibleDeviceSlugs = this.getVisibleDeviceSlugs();
      const selectedDeviceSet = new Set(this.selectedDeviceSlugs);

      // Update "select all" based on whether all visible devices are selected
      this.selectAll =
        visibleDeviceSlugs.length > 0 &&
        visibleDeviceSlugs.every((slug) => selectedDeviceSet.has(slug));
    });
  },

  toggleSelection(slug) {
    const index = this.selectedDeviceSlugs.indexOf(slug);
    if (index > -1) {
      this.selectedDeviceSlugs.splice(index, 1);
    } else {
      this.selectedDeviceSlugs.push(slug);
    }
  },

  toggleAll() {
    this.selectAll = !this.selectAll;

    this.selectedDeviceSlugs = this.selectAll
      ? this.getVisibleDeviceSlugs()
      : [];
  },

  getVisibleDeviceSlugs() {
    return Array.from(document.querySelectorAll(".gtable-row-renewals"))
      .filter((el) => el.dataset.slug && el.style.display !== "none")
      .map((el) => el.dataset.slug);
  },
}));

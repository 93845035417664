import Alpine from "alpinejs";

Alpine.data("deviceReturns", (deviceId, initialSelected, deviceOrder) => ({
  deviceId,
  selected: initialSelected,
  deviceOrder,

  init() {
    if (this.selected) {
      this.selectedDeviceIds.push(this.deviceId);
    }

    this.$watch("destination_sgid", (newValue) => {
      if (newValue) {
        // Trigger the turbo frame refresh based on the updated destination_sgid
        const turboFrame = document.querySelector("turbo-frame#return_devices");
        if (turboFrame) {
          turboFrame.src = `/purchase_orders/return_devices?destination_sgid=${newValue}&selected_device_ids=${this.selectedDeviceIds.join(",")}`;
        }
      }
    });
  },

  toggleDevice(deviceElement) {
    const { availableReturnDevices, selectedReturnDevices } = this.$refs;

    if (this.selected) {
      // Move to available devices
      this.insertInOrder(availableReturnDevices, deviceElement);
      this.selectedDeviceIds = this.selectedDeviceIds.filter(
        (id) => id !== this.deviceId,
      );
    } else {
      // Append to selected devices
      selectedReturnDevices.appendChild(deviceElement);
      this.selectedDeviceIds.push(this.deviceId);
    }

    this.selected = !this.selected;
  },

  insertInOrder(targetList, deviceElement) {
    const devices = Array.from(targetList.children);
    // Find the first device element with a higher data-order value than the current device.
    const position = devices.findIndex(
      (child) =>
        parseInt(child.getAttribute("data-order"), 10) > this.deviceOrder,
    );

    if (position === -1) {
      targetList.appendChild(deviceElement); // Append if no larger order is found
    } else {
      targetList.insertBefore(deviceElement, devices[position]); // Insert at the found position
    }
  },
}));

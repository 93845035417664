import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import Webcam from "@uppy/webcam";
import ActiveStorageUpload from "@excid3/uppy-activestorage-upload";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/webcam/dist/style.css";

const UppyLocales = {
  en: require("@uppy/locales/lib/en_US"),
  nl: require("@uppy/locales/lib/nl_NL"),
};

document.addEventListener("turbo:load", () => {
  document
    .querySelectorAll("[data-uppy]")
    .forEach((element) => setupUppy(element));
});

function setupUppy(element) {
  const form = element.closest("form");
  const directUploadUrl = document
    .querySelector("meta[name='direct_upload_url']")
    .getAttribute("content");
  const fieldName = element.dataset.uppy;
  const allowMultiple = fieldName.endsWith("[]");

  // manage buttons and button visibility
  const uppyTriggers = element.querySelectorAll(
    "[data-behaviour='uppy-trigger']",
  );
  uppyTriggers.forEach((trigger) => {
    trigger.addEventListener("click", (event) => event.preventDefault());
  });

  // if a remove button is present, make it remove the uploaded file
  const triggerRemove = element.querySelector(
    "[data-behaviour='uppy-trigger-remove']",
  );
  if (triggerRemove) {
    triggerRemove.addEventListener("click", (event) => {
      event.preventDefault();
      element
        .querySelector('[data-behaviour="uppy-preview-container"]')
        .classList.add("hidden");

      // remove files from uppy
      uppy.getFiles().forEach((file) => {
        uppy.removeFile(file.id);
      });

      // unset hidden field
      const field = element.querySelector('input[type="hidden"]');
      field.setAttribute("name", null);
      field.setAttribute("value", null);

      updateButtonVisibility();
    });
  }
  const uppy = new Uppy({
    autoProceed: true,
    // eslint-disable-next-line no-undef
    locale: UppyLocales[locale],
    restrictions: {
      maxNumberOfFiles: allowMultiple ? null : 1,
      maxTotalFileSize: 10 * 1024 * 1024,
    },
  });

  uppy.use(ActiveStorageUpload, {
    directUploadUrl,
  });
  uppy
    .use(Dashboard, {
      trigger: "[data-behaviour='uppy-trigger']",
      closeAfterFinish: true,
    })
    .use(Webcam, {
      modes: ["picture"],
      videoConstraints: {
        width: { ideal: 1920 },
        height: { ideal: 1080 },
      },
    });

  uppy.on("complete", (result) => {
    result.successful.forEach((file) => {
      const previewContainer = element.querySelector(
        '[data-behaviour="uppy-preview-container"]',
      );
      const preview = previewContainer.querySelector(
        '[data-behaviour="uppy-preview"]',
      );

      if (allowMultiple) {
        // duplicate preview container
        previewContainer.parentElement.prepend(
          previewContainer.cloneNode(true),
        );

        // remove previewContainer behaviour
        previewContainer.setAttribute("data-behaviour", "");
      }

      // show preview container
      previewContainer.classList.remove("hidden");

      // set hidden field
      const field = previewContainer.querySelector('input[type="hidden"]');
      field.setAttribute("name", fieldName);
      field.setAttribute("value", file.response.signed_id);

      if (preview) {
        preview.title = file.name;
        if (file.preview) {
          preview.src = file.preview;
        } else {
          preview.alt = file.name;
        }
      }
    });
    if (form.dataset.submitToggle === "true") {
      form.submit();
    }

    updateButtonVisibility();
  });

  uppy.on("dashboard:modal-open", () => {
    // if only single file allowed, remove existing to allow for new upload
    if (!allowMultiple) {
      uppy.getFiles().forEach((file) => {
        uppy.removeFile(file.id);
      });
    }
  });

  function updateButtonVisibility() {
    const numberOfFiles = uppy.getFiles().length;
    // eslint-disable-next-line no-unused-vars
    let button;
    element.querySelectorAll(".uppy-button-upload").forEach((button) => {
      if (numberOfFiles === 0 || allowMultiple) {
        button.classList.remove("hidden");
      } else {
        button.classList.add("hidden");
      }
    });
    element
      .querySelectorAll(".uppy-button-replace,.uppy-button-remove")
      .forEach((button) => {
        if (numberOfFiles === 1 && !allowMultiple) {
          button.classList.remove("hidden");
        } else {
          button.classList.add("hidden");
        }
      });
  }
}

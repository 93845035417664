import Alpine from "alpinejs";
import TimeAgo from "javascript-time-ago";

import { minute, hour, day, year } from "javascript-time-ago/steps";

import en from "javascript-time-ago/locale/en";
import nl from "javascript-time-ago/locale/nl";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(nl);

const timeAgo = new TimeAgo(window.locale);

const yearLabels = {
  en: {
    almost: "almost",
    over: "over",
    about: "about",
  },
  nl: {
    almost: "bijna",
    over: "meer dan",
    about: "ongeveer",
  },
};

// produces virtually the same results as Rails' time_ago_in_words
const railsStyle = {
  steps: [
    { formatAs: "now" },
    { formatAs: "minute", minTime: 30 },
    { formatAs: "hour", minTime: 44 * minute + 30 },
    { formatAs: "day", minTime: 23 * hour + 59 * minute + 30 },
    { formatAs: "month", minTime: 29 * day + 23 * hour + 59 * minute + 30 },
    {
      format(date, locale, { formatAs, now }) {
        const days = Math.round(Math.abs((now - date) / (day * 1000)));
        const years = Math.floor(days / 365);
        const remainder = days % 365;
        if (remainder < 90) {
          return yearLabels[locale].about + " " + formatAs("year", -years);
        } else if (remainder > 275) {
          return yearLabels[locale].almost + " " + formatAs("year", -years - 1);
        } else {
          return yearLabels[locale].over + " " + formatAs("year", -years);
        }
      },
      minTime: year,
    },
  ],
};

Alpine.directive("time-ago", (el, { expression }) => {
  el.innerHTML = timeAgo.format(new Date(expression), railsStyle);
});
